<template>
  <v-container fluid no-gutters>
    <B2cTabs/>
    <v-row class="mt-10" justify="center">
      <v-card class="mx-0" elevation="2" max-width="100%">
        <v-slide-group
          v-model="model"
          center-active
          class="pa-4"
          mandatory
          show-arrows
        >
          <v-slide-item
            v-for="vs in venueServices"
            :key="vs.id"
            v-slot="{ active, toggle }"
          >
            <v-card
              :color="active ? '#BBDEFB' : 'white'"
              class="ma-4"
              height="100"
              width="260"
              @click="
                () => {
                  toggle();
                  selectedService = vs;
                  getVenueServiceProductInfo(vs.id);
                }
              "
            >
              <v-card-title>
                <v-row align="center" class="fill-height" justify="center">
                  <v-col class="ma-0 pa-0" cols="4" justify="center">
                    <view-image
                      :contain="false"
                      :image="vs.image_path"
                      defaultImage="event"
                      style="border-radius: 4px; height: 93px"
                    ></view-image>
                  </v-col>
                  <v-col cols="8">
                    <h3 style="margin-top: auto; margin-bottom: auto">
                      {{ vs.name }}
                    </h3>
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-card>
    </v-row>

    <v-row class="mt-10" justify="center">
      <v-card style="width: 100%; min-width: 300px">
        <h2 class="pa-4">{{ selectedService.name }}</h2>
        <!-- <v-card> -->
        <v-row>
          <v-col class="pr-0 ml-4 mb-4">
            <image-upload
              :height="291"
              :image_path="venueServiceProductInfo.image_path"
              class="mr-5"
              defaultImage="event"
              @remove="
                () => {
                  venueServiceProductInfo.image = null;
                }
              "
              @upload="
                (data) => {
                  venueServiceProductInfo.image = data;
                }
              "
            ></image-upload>
          </v-col>
          <v-col class="pl-0">
            <v-row>
              <v-col class="pb-0" cols="9">
                <v-text-field
                  v-model="venueServiceProductInfo.title"
                  class="mx-6 mb-4"
                  label="Page title"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-if="is_arabic_enabled"
                  v-model="venueServiceProductInfo.ar_title"
                  class="mx-6"
                  label="Page title(AR)"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="pb-0 pl-0" cols="3">
                <v-switch
                  v-model="venueServiceProductInfo.is_public"
                  label="Enable"
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="px-8 mb-4">
          <v-col :cols="is_arabic_enabled ? 6 : 12">
            <label for="">Description</label>
            <ckeditor
              id="editor"
              v-model="venueServiceProductInfo.description"
              :config="editorConfig"
              :editor="editor"
              style="min-height: 400px"
            />
            <!--            <v-textarea-->
            <!--                class="mx-9 mb-4"-->
            <!--                outlined-->
            <!--                v-model="venueServiceProductInfo.description"-->
            <!--                rows="5"-->
            <!--                name="input-7-4"-->
            <!--                label="Description"-->
            <!--                value=""-->
            <!--                height="203px"-->
            <!--            ></v-textarea>-->
          </v-col>
          <v-col cols="6" v-if="is_arabic_enabled">
            <!--            <v-textarea-->
            <!--                class="mx-9"-->
            <!--                outlined-->
            <!--                v-model="venueServiceProductInfo.ar_description"-->
            <!--                rows="5"-->
            <!--                name="input-7-4"-->
            <!--                label="Description (AR)"-->
            <!--                value=""-->
            <!--                height="203px"-->
            <!--            ></v-textarea>-->
            <label for="">Description (AR)</label>
            <ckeditor
              id="editor"
              v-model="venueServiceProductInfo.ar_description"
              :config="editorConfig"
              :editor="editor"
              style="min-height: 400px"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              class="mb-5 mt-2 mr-7 white--text blue-color"
              width="100px"
              @click="submitData"
              >Save
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import { CustomUploadAdapterPlugin } from "@/plugins/UploadAdapter";
import B2cTabs from "@/views/B2CConfigurations/B2cTabs.vue";

export default {
  components: {
    B2cTabs,
    ckeditor: CKEditor.component,
  },
  beforeMount() {
    console.log("VS", this.$store.getters.getVenueServices);
  },
  mounted() {
    this.btnShow = true;
    this.getServices();
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        extraPlugins: [CustomUploadAdapterPlugin],
        mediaEmbed: { previewsInData: true },
      },
      model: null,

      venueServices: null,
      selectedService: {
        name: "",
      },

      venueServiceProductInfo: {
        title: "",
        ar_title: "",
        description: "",
        ar_description: "",
        image: null,
        image_path: "",
        is_public: true,
      },

      btnShow: false,
      is_arabic_enabled: 0,
    };
  },

  methods: {
    getServices() {
      this.showLoader("Loading");

      this.$http
        .get("venues/services")
        .then((response) => {
          if (response.status == 200) {
            this.venueServices = response.data.data;
            if (this.venueServices.length > 0) {
              this.selectedService = this.venueServices[0];
              this.getVenueServiceProductInfo(this.selectedService.id);
            } else {
              this.selectedService.name = "No services available";
            }
            this.hideLoader();
          }
        })
        .catch((error) => {
          console.log(error);
          this.hideLoader();
        });
    },

    getVenueServiceProductInfo(id) {
      this.$http
        .get("venues/b2c/configurations/services/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.venueServiceProductInfo.title = response.data.data.name;
            this.venueServiceProductInfo.ar_title = response.data.data.ar_name;
            this.venueServiceProductInfo.description =
              response.data.data.description;
            this.venueServiceProductInfo.ar_description = response.data.data
              .ar_description
              ? response.data.data.ar_description
              : "<p></p>";
            this.venueServiceProductInfo.image_path =
              response.data.data.image_path;
            this.venueServiceProductInfo.image = null;
            this.venueServiceProductInfo.is_public =
              response.data.data.is_public;
            this.is_arabic_enabled = response.data.data.enable_arabic;

            this.hideLoader();
          }
        })
        .catch((error) => {
          console.log(error);
          this.venueServiceProductInfo = {
            title: "",
            description: "",
            image: null,
            image_path: "",
            is_public: false,
          };
          this.hideLoader();
        });
    },

    submitData() {
      this.showLoader("Loading");

      let formData = new FormData();

      if (this.venueServiceProductInfo.title) {
        formData.append(
          "services[0][name]",
          this.venueServiceProductInfo.title
        );
      }
      if (this.venueServiceProductInfo.ar_title) {
        formData.append(
          "services[0][ar_name]",
          this.venueServiceProductInfo.ar_title
        );
      }
      if (this.venueServiceProductInfo.description) {
        formData.append(
          "services[0][description]",
          this.venueServiceProductInfo.description
        );
      }
      if (this.venueServiceProductInfo.ar_description) {
        formData.append(
          "services[0][ar_description]",
          this.venueServiceProductInfo.ar_description
        );
      }
      if (this.venueServiceProductInfo.is_public !== null) {
        formData.append(
          "services[0][is_public]",
          this.venueServiceProductInfo.is_public ? 1 : 0
        );
      }
      if (this.venueServiceProductInfo.image) {
        formData.append(
          "services[0][image]",
          this.venueServiceProductInfo.image
            ? this.venueServiceProductInfo.image
            : ""
        );
      }

      if (this.selectedService.id) {
        formData.append(
          "services[0][venue_service_id]",
          this.selectedService.id
        );
      }

      formData.append("product_type", "Facility");

      this.$http
        .post(`venues/b2c/configurations/services`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Configuration saved successfully.");
            this.getVenueServiceProductInfo(this.selectedService.id);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
      this.hideLoader();
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
</style>
